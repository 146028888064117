import React from "react";
import ReactDOM from "react-dom";
import "./Quiz.scss";
import WordBank from "./WordBank"


function shuffle(a) {
	    var j, x, i;
	    for (i = a.length - 1; i > 0; i--) {
	        j = Math.floor(Math.random() * (i + 1));
	        x = a[i];
	        a[i] = a[j];
	        a[j] = x;
	    }
	    return a;
}

var Matter = window.Matter;
// import Matter from "matter-js";
// import Matter from "./matter-dev.js";
const {
  Engine,
  Render,
  World,
  Bodies,
  Mouse,
  MouseConstraint,
  Composites,
  Composite,
  Common
} = Matter;


let sidx = 0,
  cidx = 0;
function getChoice(word, content, correct, isFlip, color, isMatching) {
  const { Bodies } = Matter;
  let opt = {
    word,
    correct,
    render: {
      text: {
        content: isFlip ? content : word,
        color: "white",
        size: isFlip ? 16 : 24
      },
      fillStyle: color
    }
  };
  if (isFlip) {
    switch (sidx++ % 4) {
      case 0:
        return Bodies.rectangle(400, 100, 650, 110, opt);
      case 1:
        return Bodies.rectangle(400, 200, 650, 110, opt);
      case 2:
        return Bodies.rectangle(400, 300, 650, 110, opt);
      case 3:
      default:
        return Bodies.rectangle(400, 400, 650, 110, opt);
    }
  } else {
    switch (sidx++ % 4) {
      case 0:
        return Bodies.polygon(280, isMatching ? 400 : 100, 3, 115, opt);
      case 1:
        return Bodies.polygon(300, 360, 5, 90, opt);
      case 2:
        return Bodies.polygon(350, 260, 6, 90, opt);
      case 3:
      default:
        return Bodies.polygon(400, isMatching ? 500 : 360, 4, 100, opt);
    }    
  }
}

function getColor() {
  const colors = ["#ea4335", "#4285f4", "#fbbc04", "#34a853"];
  const id = cidx++ % colors.length;
  const color = colors[id];
  return color;
}

function getWord(word) {
  return `<span class="word-click" onclick='window.dx.lookup("${word}")'>${word}</span>`;

}

class WordBankView extends React.Component {
  constructor(props) {
    super(props);
    this.wb = new WordBank(props.wbDb);
  }

  render() {
    this.wb = new WordBank(this.props.wbDb);
    const ls = [5,4,3,2,1];
    const divs = ls.map(l => {
      const innerHTML = `<span class="count">${l}: ${this.wb.U[l].size}</span> - ${[...this.wb.U[l]].map(getWord).join(", ")}`;
      return (
        <div className={`vx${l}`} dangerouslySetInnerHTML={{ __html: innerHTML }}>
        </div>);
        
    });
    return (
    <div className="word-bank-view">
      {divs}
    </div>);
  }
}

class Quiz extends React.Component {
  constructor(props) {
    super(props);
    this.wb = new WordBank(props.wbDb);
    this.state = {
      status: "off",
      qNo: 0,
      nCorrect: 0,
      correct: true,
      prob: this.getProblem(),
    };
    this.onNext = this.onNext.bind(this);
    this.showStatusBox = this.showStatusBox.bind(this);
    this.isFlip = this.isFlip.bind(this);
    this.refresh = this.refresh.bind(this);
    this.handleChoiceClick = this.handleChoiceClick.bind(this);
    this.getProblem = this.getProblem.bind(this);
    this.getQType = this.getQType.bind(this);
    this.clickDone = this.clickDone.bind(this);
  }



  componentDidMount() {
    var engine = Engine.create({
      // positionIterations: 20
    });

    var render = Render.create({
      element: this.refs.scene,
      engine: engine,
      options: {
        width: 800,
        height: 600,
        wireframes: false,
        background: "transparent"
      }
    });

    this.engine = engine;
    let walls = [// walls
      Bodies.rectangle(400, 0, 800, 10, { isStatic: true }),
      Bodies.rectangle(400, 600, 800, 10, { isStatic: true }),
      Bodies.rectangle(800, 300, 10, 600, { isStatic: true }),
      Bodies.rectangle(0, 300, 10, 600, { isStatic: true })];

    this.dividers = [
      Bodies.rectangle(400, 200, 700, 5, { isStatic: true }),
      Bodies.rectangle(400, 300, 700, 5, { isStatic: true }),
      Bodies.rectangle(400, 400, 700, 5, { isStatic: true }),
      Bodies.rectangle(400, 500, 700, 5, { isStatic: true })];

    World.add(engine.world, walls);

    // add mouse control
    var mouse = Mouse.create(render.canvas),
      mouseConstraint = MouseConstraint.create(engine, {
        mouse: mouse,
        constraint: {
          stiffness: 0.2,
          render: {
            visible: false
          }
        }
      });
    this.mouse = mouse;
    this.mouseConstraint = mouseConstraint;

    World.add(engine.world, mouseConstraint);

    Matter.Events.on(mouseConstraint, "mousemove", function(event) {
      var allBodies = Matter.Composite.allBodies(engine.world);
      const query = Matter.Query.point(allBodies, mouse.position);
      render.element.style.cursor = query.length > 0 ? "pointer" : "default";
    });
    var clickHandler = (evt) => {
      const p1 = mouse.mousedownPosition,
        p2 = mouse.mouseupPosition;
        
      const dist = Math.abs(p1.x - p2.x) + Math.abs(p1.y - p2.y);
      if (dist < 10) {
        var allBodies = Composite.allBodies(engine.world);

        const query = Matter.Query.point(allBodies, mouse.position);
        const body = query[0];

        if (body) {
          this.handleChoiceClick(body.word, body.correct);
        }
      }
    }
    const ch = clickHandler.bind(this);
    render.element.addEventListener("click", ch);
    Engine.run(engine);

    Render.run(render);
    this.refresh();
  }
  getWord(word) {
    return `<span class="word-click" onclick='window.dx.lookup("${word}")'>${word}</span>`;
  }

  handleChoiceClick(word, isCorrect) {
    if (this.props.qMode === 'matching') return;
    const {prob, status} = this.state;
    const dd = prob.qMap[word];
    window.dx.lookup(word);
    if (status =='on') {
      return;
    }
    if (isCorrect) {
      this.wb.incWord(word);
    } else {
      this.wb.decWord(word);
      this.wb.decWord(prob.word);
    }
    const txt = `<div class="vx${this.wb.db[word]} word-header">${word} :</div>
    <div class="desc">Def: ${dd.defs}</div>
    <div class="desc">Syns: ${dd.syns.map(this.getWord).join(", ")}</div>
    <div>${dd.ex?'Ex: ' +dd.ex:''}</div>`;

    WordBank.saveGDB();
    this.setState(prevState =>({
      statusText: txt,
      status: "on",
      correct: isCorrect,
      nCorrect: prevState.nCorrect + (prevState.correct && isCorrect ? 1 : 0)
    }));
    this.props.onAnswer(this.wb.db);
  }

  isFlip() {
    const { qMode } = this.props;
    if (qMode === 'mix') {
      return Common.random(0, 100) > 80;
    } else {
      return qMode == 'w2d';
    }
  }

  doOneProblem(prob) {
    const {qMap, qType, qMode} = this.props;
    const {isFlip} = this.state;
    const isMatching = qMode == 'matching';
    World.clear(this.engine.world, true);
    World.remove(this.engine.world, this.dividers);
    if (isMatching) {
      World.add(this.engine.world, this.dividers);
    }
    var stack = Composites.stack(100, 0, 5, 5, 30, 20, function(x, y) {

      return isFlip ?

             Bodies.polygon(x, y, 4, Common.random(3, 15), {
        restitution: 0.6,
        friction: 0.1,
        render: { fillStyle: "#eee" }
      })

      : Bodies.circle(x, y, Common.random(15, 30), {
        restitution: 0.6,
        friction: 0.1,
        render: { fillStyle: "#eee" }
      });
    });
    const fixQType = this.getQType(qType);
    let choices = prob.choices.map((w, i) => {
      return getChoice(w,this.showDetail(qMap[w], fixQType), i === prob.a, isFlip, prob.w2c[w], isMatching);
    });
    World.add(this.engine.world, [stack, ...choices]);
    World.add(this.engine.world, this.mouseConstraint);
  }

  getProblem() {
    const { qMap, qType, qMode } = this.props;
    const prob = WordBank.genQ(this.wb.getRandomWord(), qMap, qType, qMode === 'matching')
    return prob;
  }

  refresh() {
    this.wb = new WordBank(this.props.wbDb);
    const prob = this.getProblem();
    this.setState({prob,qNo:0,nCorrect:0,isFlip:this.isFlip()},() => this.doOneProblem(prob));
    
  }
  reset() {
    this.setState({
      qNo: 0,
      nCorrect: 0,
      correct: true,
    }, () => {
      this.refresh()
    });
  }
  onNext() {
    const finishAll = this.wb.U[5].size == this.wb.getCount();
    this.setState({status:"off",done:false,toggle:false},()=> {
      if (finishAll) {
        this.props.skipTwenty(this.refresh)
      } else {
        this.refresh()  
      }
      
    });

    return;
    if (this.state.correct) {
      this.setState(
        (prevState, props) => ({
          qNo: (prevState.qNo + 1) % props.questions.length,
          status: "off",
          nCorrect: ((prevState.qNo + 1) % props.questions.length) == 0 ? 0 : prevState.nCorrect
        }),
        this.refresh
      );      
    } else {
      this.setState(
        (prevState, props) => ({
          status: "off"
        }),
        this.refresh
      );         
    }
  }

  formatAccuracy(num) {
    return (Math.floor(num*100)) + '%';
  }

  getQType(qType) {
    if (qType === 'mix') {
      return Common.random(0, 100) > 50 ? 'defs' : 'syns';
    }
    return qType;
  }

  showDetail(prob, qType) {
    let q = "";
    if (qType === 'mix') {
      q = prob[Common.random(0, 100) > 50 ? 'defs' : 'syns'];
    } else if (qType === 'both') {
      q = prob['defs'] + '<br/>' + prob['syns'];
    } else if (qType === 'syns') {
      q = Array.isArray(prob[qType]) ? prob[qType].join(", ") : prob[qType];
    } else {
      q = prob[qType]
    }
    
    return q;
  }

  showQ(prob, qType, isFlip) {
    return isFlip ? prob['word'] : this.showDetail(prob, qType);
  }

  showStatusBox() {
        const { prob, qNo, statusText, status, correct, nCorrect } = this.state;
        const c = this.wb.getProgress();
        const celebrate = nCorrect % 10 == 0 || this.wb.U[5].size == this.wb.getCount();
    return (
     <div className={`status-box ${status} ${(celebrate ? 'done' : '')}`}>
          { celebrate && correct &&
            <>
            <img src={`common/img/gif${Math.floor(Math.random()*5)+1}.gif`} />
            <br/>
            </>
          }
          <div className="status-text" dangerouslySetInnerHTML={{__html: statusText }}></div>
          <button className={correct ? 'green' : 'red'} onClick={this.onNext}>
          { correct && 
            <>
            <i className="fa fa-check-circle"></i>

            <span> next</span>
            </>
          }
          { !correct &&
            <>
            <i className="fa fa-times-circle"></i>
            <span> try again</span>
            </>
          }
          </button>
      </div>);
  }

  clickDone() {
    const { prob } = this.state;
    var allBodies = Composite.allBodies(this.engine.world);
    const words = [];
    allBodies.forEach(b => {
      if (b.word) {
        words.push({word:b.word,y:b.bounds.max.y});
      }
    })
    words.sort((a, b) => (a.y > b.y) ? 1 : -1);
    let c = 0;
    const that = this;
    words.forEach((w,i) => {
      if (w.word === prob.shuffledChoices[i]) {        
        that.wb.incWord(w.word);
        c++;
      } else {
        that.wb.decWord(w.word);
        
      }
    })
    WordBank.saveGDB();
    this.setState(prevState =>({
      // statusText: txt,
      status: "on",
      correct: c==4,
    }));
    console.log(words);
    console.log("Checking answers");

  }

  render() {
    const { prob, qNo, statusText, status, correct, nCorrect,done, toggle, isFlip} = this.state;
    const { qMap, qType, wbDb, qMode } = this.props;
    const isMatching = qMode == 'matching';
    const wlAll = window.WL.getWordList(window.WL.getName());
    const WB = WordBank.getGWB();
    const wlNumFives = wlAll.filter(w => WB.db[w]===5).length;
    const matchingChoices = prob.shuffledChoices.map(w => {
      return (
        <div style={{backgroundColor:status=='on' ? prob.w2c[w] : ''}} className={`question ${isFlip ? 'word':''}`} dangerouslySetInnerHTML={{ __html: this.showDetail(qMap[w],this.getQType(qType)) }}></div>
        );
    })
    return (
      <div className="quiz-root">
      <div className="quiz-background">
            {isMatching && matchingChoices}
      </div>
      <div className="container">
        <div ref="scene">
          <div className="flex">
            <div className="progress">
              <span>&nbsp;</span>
              <span>Score: {`${this.wb.getProgress()}/${this.wb.getCount()*2}`}   </span>
              <span>WordList: {`${wlNumFives}/${wlAll.length}`}   </span>

            </div>
            {isMatching && <button className="done-button" onClick={this.clickDone}> Done </button>}
            {!isMatching && <div className={`question ${isFlip ? 'word':''}`} dangerouslySetInnerHTML={{ __html: this.showQ(prob,toggle?'both': qType, isFlip) }} onClick={() => {this.setState({toggle:true})}}></div>}
          </div>
        </div>
    <div className="definition">
    <ul>
    <li>
    <span>
    <div className="byline">
    meaning
    <span>|</span>
    (noun)&nbsp;
    </div>
    isolation to prevent the spread of infectious disease
    <br/>
    </span>
    </li>
    </ul>
    </div>

        <div id="chart" className="quiz-chart">

        </div>
        { this.showStatusBox() }


      </div>
      <div className="word-bank-container">
        <span id="vocabDefs"/>
        <span id="banner-side-definition"/>
        <WordBankView wbDb={wbDb} />
        <WordBankView wbDb={WordBank.getGDB()} />
      </div>
      </div>
    );
  }
}

export default Quiz;
