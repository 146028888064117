import React, { Component } from 'react';
import './SearchBar.scss';
// import SimpleSelect from './FlySelect'
// import SimpleSelect from './RCMenu'
import SimpleSelect from './DemoMenu'

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuggestions:false,
      selectedIndex:0
    };
    this.input = React.createRef();
    this.qType = React.createRef();
  }
  handleOver(idx) {
    this.setState({
      selectedIndex:idx
    });
    // console.log(evt.target);
    // console.log(evt.target.innerText);
  }
  handleClick(evt) {
    var word = evt.target.innerText;
    this.props.lookup(word);
    // console.log(word);
  }
  getSelectedWord() {

    if (this.state.selectedIndex >= 0) {
      var word = this.props.data[this.state.selectedIndex];
      return word;
    }

  }

  getLookUpWord() {
    let selectedWord = this.getSelectedWord();
    let sLen = selectedWord ? selectedWord.length : 0;
    let word = this.props.word.length > sLen ? this.props.word: selectedWord;
    return word.toLowerCase();
  }
  renderSearchBox() {
    const listItems = this.props.data.map((word, idx) => {
      if (this.state.selectedIndex === idx) {
        return <li onMouseOver={() => this.handleOver(idx)} onClick={(evt) => {
          this.props.lookup(this.getLookUpWord());
        }} key={word} className="suggestion-item selected-item">{word}</li>
      } else {
        return <li onMouseOver={() => this.handleOver(idx)} key={word} className="suggestion-item">{word}</li>  
      }

    }, this
    );
    return (
      <form className="search-form" method="get" action="">
      <div>


      <input ref={this.input} className="search-text" placeholder="Search a word ..." type="text"  onChange={this.props.onChange} value={this.props.word}
      onKeyDown={(evt) => {
        if (evt.key === 'ArrowDown' || evt.key === 'ArrowUp') {
          var index = this.state.selectedIndex ? this.state.selectedIndex : 0;
          if (evt.key === 'ArrowDown') {
            index++;
          } else {
            index--;
          }
          if (index <0) {
            index = this.props.data.length - 1;
          } else {
            index = index % this.props.data.length;
          }
          this.setState({
            selectedIndex: index
          });
        } else {
         this.setState({
          selectedIndex: 0
        });
         if (evt.key === 'Enter') {
          evt.preventDefault();
          this.props.lookup(this.getLookUpWord());
          this.input.current.blur();

        }
      }
    }}
    onFocus={(evt) => {
      this.setState({
        showSuggestions:true
      });
    }} onBlur={(evt) => {
                      // this.setState({
                      //   showSuggestions:false
                      // });
                    }}
                    />
                    <input className="search-submit button" name="submit" type="submit" value="Search"/>
                    {this.state.showSuggestions ? 
                      <ul className="search-suggest" > {listItems}</ul> 
                      : null
                    }
                    
                    </div>
                    </form>
                    )
  }

  render() {


    const testType = <span>
    <select id="testMode" ref={this.qType} onChange={(e)=>{this.props.setQType(e.target.value)}}>
        <option value='mix'>Mix</option>
        <option value='defs'>Def</option>
        <option value='syns'>Syns</option>
        <option value='both'>Both</option>
    </select>

    <select onChange={(e)=>{this.props.setQMode(e.target.value)}}>
      <option value='mix'>Mix</option>    
      <option value='d2w'>Detail -> Word</option>
      <option value='w2d'>Word -> Detail</option>
      <option value='matching'>Matching</option>

    </select>

    <button className="quiz-button round-button purple" onClick={this.props.skipTwenty}> Next 20 Words </button>

    </span>

    return (
      <div className="header-content">
      {this.renderSearchBox()}

      <SimpleSelect wordListMenu={this.props.wordListMenu} wordListNames={this.props.wordListNames} setWordListName={this.props.setWordListName} wordListName={this.props.wordListName}/>

      <a id="icon-next" className={this.props.isMobile ?"next-word" : "hover next-word"}></a>
        <div style={{display:'flex','alignItems': 'center'}}>

        <button className="quiz-button round-button purple" onClick={() => this.props.toggleQuiz()}> {this.props.isQuizMode ? 'Dictionary' : 'Quiz'} </button>
        {this.props.isQuizMode && testType}

        </div>
      </div>

      );
  }
}

export default SearchBar;
