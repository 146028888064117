import React from 'react';
import './Flickr.css';

class Flickr extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items:[]
        };
    }

    search(word, numQuery, numShow) {
        let that = this;
        window.$.getJSON("http://api.flickr.com/services/feeds/photos_public.gne?jsoncallback=?", {
            tags: word,
            tagmode: "any",
            format: "json"
        }, function(data) {
            that.setState({items:data.items});
        });

    }

    componentDidMount() {
        this.search(this.props.word);
    }

    componentDidUpdate(prevProps, prevStates) {
        if (prevProps.word !== this.props.word) {
            this.search(this.props.word);
        }
    }

    render() {
        const lis = this.state.items.map((item, index) => 
            <li key={index}><a href={item.link}><img src={item.media.m.replace("_m.jpg", "_s.jpg")} title={item.title}/></a></li>
        );
      return(
        <div id="flickrrss" className="widget widget_flickrRSS widget-widget_flickrRSS">
                    <div className="widget-wrap widget-inside">
                      <h3 className="widget-title">Flickr Images</h3>
                      <div className="page-divider"></div>
                      <ul id="flickrlist">
                        {lis}
                      </ul>
                      </div>
                    </div>
        );

    }
}

export default Flickr;