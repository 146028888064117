import React, { Component } from 'react';
import './BookSearch.css';
import { Amz } from './Amz'
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';


function PaperComponent(props) {
  return (
    <Draggable>
      <Paper {...props} />
    </Draggable>
  );
}


class BookSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      items:[],
      bookOffset:0,
      paragraph:"",
      selectedParagraph:"",
      showModal:false
    };
  }
  async handleClickOpen(item) {
    this.getThisBook(item);

      // try {

      //   this.setState({
      //     selectedParagraph : searchHit
      //   });

      //   let paragraphs = await this.getParagraphs(searchHit._source.title, searchHit._source.location - 5)
      //   this.setState({
      //     paragraphs
      //   });
      //   this.setState({ open: true });

      // } catch (err) {
      //   console.error(err)
      // }

  }

  handleClose = () => {
    this.setState({
      paragraphs: null,
      showModal:false,
      selectedParagraph: null,
      open: false
    })
  };
  componentDidUpdate(prevProps) {
    if (prevProps.word !== this.props.word) {
      this.performBookSearch(this.props.word);
    }
  }
  componentDidMount() {
    this.performBookSearch(this.props.word);
  }

  async performBookSearch(word) {
    const url = `https://www.googleapis.com/books/v1/volumes?q=${word}+inpublisher:scholastic`
    if (word.length > 0) {
      const res = await axios.get(url);
      this.setState({items:res.data.items});
      // axios.get(url)
      //   .then(res => {
      //     this.setState({items:res.data.hits.hits });
      //   })
      // fetch(url)
      //   .then(resp => resp.json())
      //   .then(data => {
      //     this.setState({items:data.hits.hits});
      //   });       
    }
  }

  /** Call the API to get current page of paragraphs */
    async getParagraphs (bookTitle, offset) {
      try {
        this.bookOffset = offset
        const start = this.bookOffset
        const end = this.bookOffset + 10
        const response = await axios.get(`${this.props.baseUrl}/paragraphs`, { params: { bookTitle, start, end } })
        this.setState({bookOffset:offset})
        return response.data.hits.hits
      } catch (err) {
        console.error(err)
      }
    }
    /** Get next page (next 10 paragraphs) of selected book */
    async nextBookPage () {
      // this.$refs.bookModal.scrollTop = 0
      const paragraphs = await this.getParagraphs(this.state.selectedParagraph._source.title, this.state.bookOffset + 10)
      this.setState({paragraphs:paragraphs});
    }
    /** Get previous page (previous 10 paragraphs) of selected book */
    async prevBookPage () {
      // this.$refs.bookModal.scrollTop = 0
      const paragraphs = await this.getParagraphs(this.state.selectedParagraph._source.title, this.state.bookOffset - 10)
      this.setState({paragraphs:paragraphs});
    }
    /** Display paragraphs from selected book in modal window */
    async showBookModal (searchHit) {
      this.setState({showModal:true})
      try {
        document.body.style.overflow = 'hidden'
        this.setState({
          selectedParagraph : searchHit
        });

        let paragraphs = await this.getParagraphs(searchHit._source.title, searchHit._source.location - 5)
        this.setState({
          paragraphs
        });
      } catch (err) {
        console.error(err)
      }
    }
    /** Close the book detail modal */
    closeBookModal () {
      document.body.style.overflow = 'auto';      
      this.setState({
        paragraphs: null,
        showModal:false,
        selectedParagraph: null
      })
    }

    getThisBook(item) {
      window.open(this.bookUrl(item),'_blank');
      // console.log(`Title: ${this.state.selectedParagraph._source.title}`);
    }

    bookUrl(item) {
      return `https://www.amazon.com/s?k=${encodeURIComponent(this.bookAuthor(item))}&tag=${this.props.amzTag}`;
      // return `https://www.amazon.com/dp/${item.volumeInfo.industryIdentifiers[1].identifier&tag=vocabx-20`;
      // return "https://www.amazon.com/gp/product/059035342X/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=059035342X&linkCode=as2&tag=sakunkoo05-20&linkId=237c39e78df2d66250a113ebd4879fcb";   
          }
    bookImg(item) {
      // return <img src=""/>;
      if (item.volumeInfo && item.volumeInfo.imageLinks && item.volumeInfo.imageLinks.smallThumbnail) {
        return <a><img src={item.volumeInfo.imageLinks.smallThumbnail}/></a>;  
      } else {
        return null;
      }
      
      // return <img className="bookImg" border="0" height="150px" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=059035342X&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=sakunkoo05-20" />;
    }
    bookKey(item) {
      item = item ? item : this.state.selectedParagraph;
      return `${item._source.title}-${item._source.author}`;
    }
    bookAuthor(item) {
      return item.volumeInfo.title  + (item.volumeInfo.authors?" - " + item.volumeInfo.authors.join(', '):'');
    }
    bookDetail(item) {
      if (item.searchInfo) {
        return item.searchInfo.textSnippet;
      } else {
        return false;
      }
    }
    bookFilter(item) {
      return item.searchInfo && item.volumeInfo && item.volumeInfo.imageLinks && item.volumeInfo.imageLinks.smallThumbnail;
    }
  render() {
    if (this.state.items) {
      const listItem = this.state.items.filter(item => this.bookFilter(item) !== false).map( (item, idx) =>
        <div key={idx} className="bookbox" onClick={(evt) => {
          // this.showBookModal(item)
          this.getThisBook(item)
        }}>
          <div>
          <div className="cover">{this.bookImg(item)}</div>
          <div className="read-button"
  onClick={(evt) => {
          // this.showBookModal(item)
          this.getThisBook(item)
        }}
          >View</div>
          </div>
          <div className="detail">
            <div className="caption" dangerouslySetInnerHTML={{ __html: this.bookDetail(item) }}></div>
            <div className="author">{this.bookAuthor(item)}</div>
          </div>
        </div>
      );
      
      return (
        <div className="bookSearch"> 
        <span id="book-widget">{listItem}</span>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default BookSearch;
