import React, { Component } from 'react';
import './BookSearch.css';
import { Amz } from './Amz'
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';


function PaperComponent(props) {
  return (
    <Draggable>
    <Paper {...props} />
    </Draggable>
    );
}


class BookSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      items:[],
      bookOffset:0,
      paragraph:"",
      selectedParagraph:"",
      showModal:false
    };
  }
  async handleClickOpen(searchHit) {
    try {

      this.setState({
        selectedParagraph : searchHit
      });

      let paragraphs = await this.getParagraphs(searchHit._source.title, searchHit._source.location - 5)
      this.setState({
        paragraphs
      });
      this.setState({ open: true });

    } catch (err) {
      console.error(err)
    }

  }

  handleClose = () => {
    this.setState({
      paragraphs: null,
      showModal:false,
      selectedParagraph: null,
      open: false
    })
  };
  componentDidUpdate(prevProps) {
    if (prevProps.word !== this.props.word) {
      this.performBookSearch(this.props.word);
    }
  }
  componentDidMount() {
    this.performBookSearch(this.props.word);
  }

  async performBookSearch(word) {
    const url = this.props.baseUrl + "/search?term="+word;
    if (word.length > 0) {
      const res = await axios.get(url);
      this.setState({items:res.data.hits.hits});
      // axios.get(url)
      //   .then(res => {
      //     this.setState({items:res.data.hits.hits });
      //   })
      // fetch(url)
      //   .then(resp => resp.json())
      //   .then(data => {
      //     this.setState({items:data.hits.hits});
      //   });       
    }
  }

  /** Call the API to get current page of paragraphs */
  async getParagraphs (bookTitle, offset) {
    try {
      this.bookOffset = offset
      const start = this.bookOffset
      const end = this.bookOffset + 10
      const response = await axios.get(`${this.props.baseUrl}/paragraphs`, { params: { bookTitle, start, end } })
      this.setState({bookOffset:offset})
      return response.data.hits.hits
    } catch (err) {
      console.error(err)
    }
  }
  /** Get next page (next 10 paragraphs) of selected book */
  async nextBookPage () {
      // this.$refs.bookModal.scrollTop = 0
      const paragraphs = await this.getParagraphs(this.state.selectedParagraph._source.title, this.state.bookOffset + 10)
      this.setState({paragraphs:paragraphs});
    }
    /** Get previous page (previous 10 paragraphs) of selected book */
    async prevBookPage () {
      // this.$refs.bookModal.scrollTop = 0
      const paragraphs = await this.getParagraphs(this.state.selectedParagraph._source.title, this.state.bookOffset - 10)
      this.setState({paragraphs:paragraphs});
    }
    /** Display paragraphs from selected book in modal window */
    async showBookModal (searchHit) {
      this.setState({showModal:true})
      try {
        document.body.style.overflow = 'hidden'
        this.setState({
          selectedParagraph : searchHit
        });

        let paragraphs = await this.getParagraphs(searchHit._source.title, searchHit._source.location - 5)
        this.setState({
          paragraphs
        });
      } catch (err) {
        console.error(err)
      }
    }
    /** Close the book detail modal */
    closeBookModal () {
      document.body.style.overflow = 'auto';      
      this.setState({
        paragraphs: null,
        showModal:false,
        selectedParagraph: null
      })
    }

    getThisBook() {
      window.open(this.bookUrl(),'_blank');
      console.log(`Title: ${this.state.selectedParagraph._source.title}`);
    }

    bookUrl(key) {
      const book = Amz[key ? key : this.bookKey()];
      return book ? book['link'] : null;
      // return "https://www.amazon.com/gp/product/059035342X/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=059035342X&linkCode=as2&tag=sakunkoo05-20&linkId=237c39e78df2d66250a113ebd4879fcb";   
    }
    bookImg(key) {
      const book = Amz[key ? key : this.bookKey()];
      return book ? book['img'] : null;
      // return <img className="bookImg" border="0" height="150px" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=059035342X&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=sakunkoo05-20" />;
    }
    bookKey(item) {
      item = item ? item : this.state.selectedParagraph;
      return `${item._source.title}-${item._source.author}`;
    }
    render() {
      const listItem = this.state.items.map( (item, idx) =>
        <div key={idx} className="bookbox" onClick={(evt) => {
        // this.showBookModal(item)
        this.handleClickOpen(item)
      }}>
      <div>
      <div className="cover" dangerouslySetInnerHTML={{ __html: this.bookImg(this.bookKey(item)) }}></div>
      <div className="read-button"
      onClick={(evt) => {
        // this.showBookModal(item)
        this.handleClickOpen(item)
      }}
      >Read</div>
      </div>
      <div className="detail">
      <div className="caption" dangerouslySetInnerHTML={{__html: item.highlight.text[0]}}></div>
      <div className="author">{(item._source.title + " - " + item._source.author)}</div>
      </div>
      </div>
      );
      const paragraphs = this.state.paragraphs ? this.state.paragraphs.map( (paragraph, idx) => 
        <div key={idx}>
        { paragraph._source.location === this.state.selectedParagraph._source.location ?
          <p className="bookText">
          <strong>{paragraph._source.text}</strong>
          </p>
          :
          <p className="bookText">
          {paragraph._source.text}
          </p>


        }
        <br/>
        </div>
        ) : null;
      return (
        <div className="bookSearch">
        { this.state.showModal ? 
          <div className="book-modal">
          <div className="paragraphs-container">
          <div className="title-row">
          <div className="mui--text-display2 all-caps">{this.state.selectedParagraph._source.title }</div>
          <div className="mui--text-display1">{this.state.selectedParagraph._source.author}</div>
          </div>
          <br/>
          <div className="mui-divider"></div>
          <div className="mui--text-subhead locations-label">Locations { this.state.bookOffset - 5 } to { this.state.bookOffset + 5 }</div>
          <div className="mui-divider"></div>
          <br/>

          <div>
          {paragraphs}
          <br/>

          </div>
          </div>

        {/* Book Pagination Footer */}
        <div className="modal-footer">
        <button className="mui-btn mui-btn--flat" onClick={evt => this.prevBookPage()}>Prev Page</button>
        <button className="mui-btn mui-btn--flat" onClick={(evt) => {this.closeBookModal();}}>Close</button>
        <button className="mui-btn mui-btn--flat" onClick={(evt) => this.nextBookPage()}>Next Page</button>
        </div>
        </div>
        : null }
        {this.state.open ?
          <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          >
          <DialogTitle id="draggable-dialog-title">
          { this.bookKey() &&
            <div className="bookImg" dangerouslySetInnerHTML={{ __html: this.bookImg() }}></div>
          }

          <a href={this.bookUrl()}>
          <p className="bookDesc">{this.state.selectedParagraph._source.title } <br/>
          by<br/>
          {this.state.selectedParagraph._source.author} 
          </p>
          </a>
          </DialogTitle>
          <DialogContent>
          
          <DialogContentText>
          {paragraphs}

          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <div className="modal-footer">
          {this.state.paragraphs[9]._source.location > this.state.selectedParagraph._source.location ?
            <Button variant="flat" onClick={evt => this.prevBookPage()}>Prev Page</Button>
            : Amz[this.bookKey()] && <Button variant="flat" onClick={evt => this.getThisBook()}>See on Amazon</Button>
          }
          <Button variant="flat" onClick={evt => this.handleClose()}>Close</Button>
          {this.state.paragraphs[0]._source.location < this.state.selectedParagraph._source.location ?
            <Button variant="flat" onClick={evt => this.nextBookPage()}>Next Page</Button>
            : Amz[this.bookKey()] && <Button variant="flat" onClick={evt => this.getThisBook()}>See on Amazon</Button>
          }
          </div>
          </DialogActions>
          </Dialog>
          : null }
          <span id="book-widget">{listItem}</span>
          </div>
          );
    }
  }

  export default BookSearch;
