function shuffle(a) {
	    var j, x, i;
	    for (i = a.length - 1; i > 0; i--) {
	        j = Math.floor(Math.random() * (i + 1));
	        x = a[i];
	        a[i] = a[j];
	        a[j] = x;
	    }
	    return a;
}
function nextInt(n) {
  return Math.floor(Math.random() * n);
}


class WordBank {


	constructor(db) {
		this.U = [];
		for (let i = 0; i <= 5; i++) {
			this.U.push(new Set());
		}
		if (db) {
			for (const [key, value] of Object.entries(db)) {
				this.U[value].add(key)
			}
		}
		this.db = db || {};
	}

	adjWord(word, delta) {
        let newS = (this.db[word] || 3) + delta;
        if (newS > 5) newS = 5;
        if (newS < 1) newS = 1;
        this._setWord(word,newS);

	}

    _setWord(word, score) {
        const oldS = this.db[word] || 3;
        this.db[word] = score;
        if (this.U[oldS].has(word)) {
        	this.U[oldS].delete(word);
        }
        this.U[score].add(word);
		if (this !== WordBank.getGWB()) {
			WordBank.getGWB()._setWord(word, score);
		}

    }

	incWord(word) {
		this.adjWord(word,+1);
	}

	wrongWord(word) {
		this._setWord(word,1);
	}

	decWord(word) {
		this.adjWord(word,-1);
	}


	addWord(word, score) {
		const s = score || 3;
		this.db[word] = s;
		this.U[s].add(word);
	}
    getScore(word) {
    	return this.db[word] || 3;
    }

    getProgress() {
    	let sum = 0;
    	for (let i = 0; i <= 5; i++) {
    		let c = this.U[i].size;
    		sum += c*(i-3);
    	}
    	return sum;
    }

	getCount() {
		return Object.keys(this.db).length;
	}

	static getGDB() {
		if (localStorage && localStorage.getItem("vxDB")) {
			return JSON.parse(localStorage.getItem("vxDB"));
		}
		return {
		};
	}


	static getGWB() {
		if (window && window.vxWB) {
			return window.vxWB;
		}
		const gWB = new WordBank(WordBank.getGDB());
		if (window) {
			window.vxWB = gWB;
		}
		return gWB;

	}

	static getWBFromWords(words) {
		const WB = WordBank.getGWB();
		const wb = new WordBank();
		for (let w of words) {
			wb.addWord(w, WB.getScore(w));
		}
		return wb;
	}

	static saveGDB() {
		if (localStorage) {
			localStorage.setItem("vxDB", JSON.stringify(WordBank.getGWB().db));
		}
	}

	static async getDefs(words) {
		const opt = {
			method: 'POST',
			body: JSON.stringify({words,}),
		};
		const res = await fetch(`https://vocabularyx.com/defs`, opt);
		const data = await res.json();
		return data;
	}

	getRandomWord() {
		const candidates = shuffle(Object.keys(this.db)).filter(w => this.db[w] !== 5);
		if (candidates.length > 0) {
			return candidates[0];
		}
		return null;
	}
	static genDefs(qMap, word) {
		return `Meaning: ${qMap[word].defs}`
	}
	static genSyns(qMap, word) {
		return `Synonyms: ${qMap[word].syns.join(", ")}`
	}

	static genQ(word, qMap, qType, lessBlue) {
		let words = shuffle(Object.keys(qMap)).filter(w => w!==word)
		if (lessBlue) {
			const WB = WordBank.getGWB();

			const words5 = words.filter(w => WB.getScore(w)==5);
			words = words.filter(w => WB.getScore(w)!==5);
			while (words.length<4) {
				words.push(words5.pop());
			}
			if (words5.length>0) {
				words.push(words5.pop());	
			}
			if (words5.length>0) {
				words.push(words5.pop());	
			}
		}
		const choices = shuffle([...words]).splice(0,4);
		let pos = choices.indexOf(word);
		if (pos < 0) {
			pos = nextInt(4);
		}
		choices[pos] = word;
    const colors = shuffle(["#ea4335", "#4285f4", "#fbbc04", "#34a853"]);
    const w2c = {};
    choices.forEach((w,i) => {
    	w2c[w]=colors[i];
    });
		const q = {
	        syns: WordBank.genSyns(qMap, word),
	        defs: WordBank.genDefs(qMap, word),
	        choices: choices,
	        shuffledChoices: shuffle([...choices]),
	        colors,
	        w2c,
	        a: pos,
	        qMap,
	        word,
  		};
  		return q;
	}



}

WordBank.getGWB();

// const ww = [
// ['zero'],
// ['one'],
// ['two'],
// ['three'],
// ['four'],
// ['five']
// ];

// const wd = {
// 	'zero':0,
// 	'one':1,
// 	'two':2,
// 	'three':3,
// 	'four':4,
// 	'five':5,
// 	'hello':0,
// 	'happy':1,
// 	'cogent':3,
// 	'assimilate':4,
// 	'belligerent':5,
// 	'comprehensive':2
// }

// const wd2 = {
// 	'anomaly':5, 'assuage':5, 'enigma':5, 'equivocal':5, 'erudite':5, 'fervid':5, 'lucid':5, 
// 	'opaque':5, 'placate':5, 'precipitate':5, 'prodigal':5, 'zeal':5, 'abstain':5, 'adulterate':5, 'apathy':5, 
// 	'capricious':2, 'corroborate':2, 'homogenous':2, 'desiccate':2, 'engender':2, 'ephemeral':2, 
// }

// wb = new WordBank(wd2);
// // wb.incWord('hello');
// // wb.decWord('comprehensive');
// // wb.decWord('comprehensive');
// // wb.decWord('comprehensive');
// console.log(wb.U);
// console.log(wb.db);

export default WordBank;
