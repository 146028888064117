import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import './index.css';

var rootEl = document.getElementById('root');
var xx = window.matchMedia("(max-width: 425px)");
var isMobile = xx.matches || window.screen.width <= 425;
var componentProps = {
		ref: function (ref) {
			window.dx = ref;
		},
		wordListNames: window.WL.getNames(),
		wordListName: window.WL.getName(),
		wordListMenu: window.WL.menu,
        isMobile
	};

ReactDOM.render(React.createElement(App, componentProps, null), rootEl);
registerServiceWorker();
