/* eslint no-console:0 */
import * as React from 'react';
import ReactDOM from 'react-dom';
import Menu, { SubMenu, Item as MenuItem, Divider } from 'rc-menu';
import 'rc-menu/assets/index.css';
import animate from 'css-animation';
import './DemoMenu.css'

const getSvgIcon = (style = {}, text) => {
  if (text) {
    return (
      <i style={style}>
        {text}
      </i>
    );
  }
  const path = 'M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h' +
    '-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v' +
    '60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91' +
    '.5c1.9 0 3.8-0.7 5.2-2L869 536.2c14.7-12.8 14.7-35.6 0-48.4z';
  return (
    <i style={style}>
      <svg
        viewBox="0 0 1024 1024"
        width="1em"
        height="1em"
        fill="currentColor"
        style={{ verticalAlign: '-.125em' }}
      >
        <path d={path} />
      </svg>
    </i>
  );
};

function itemIcon(props) {
  return getSvgIcon({
    position: 'absolute',
    right: '1rem',
    color: props.isSelected ? 'pink' : 'inherit',
  });
}

function expandIcon(props) {
  return getSvgIcon({
    position: 'absolute',
    right: '1rem',
    transform: `rotate(${props.isOpen ? 90 : 0}deg)`,
  });
}

const animation = {
  enter(node, done) {
    let height;
    return animate(node, 'rc-menu-collapse', {
      start() {
        height = node.offsetHeight;
        node.style.height = 0;
      },
      active() {
        node.style.height = `${height}px`;
      },
      end() {
        node.style.height = '';
        done();
      },
    });
  },

  appear() {
    return this.enter.apply(this, arguments);
  },

  leave(node, done) {
    return animate(node, 'rc-menu-collapse', {
      start() {
        node.style.height = `${node.offsetHeight}px`;
      },
      active() {
        node.style.height = 0;
      },
      end() {
        node.style.height = '';
        done();
      },
    });
  },
};

class Demo extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      isActive: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleMainWordClick = this.handleMainWordClick.bind(this);
  }

  onOpenChange = (value) => {
    console.log('onOpenChange', value);
  }

  handleClick = (info) => {
    this.props.setWordListName(info.key);
    this.setState({isActive:false});
    // console.log(`clicked ${info.key}`);
    // console.log(info);
  }

  handleMainWordClick = () => {
    this.setState( (prevState) => ({
      isActive: !prevState.isActive
    }));
  }

  renderCommonMenu = (props = {}, orgProps) => {

  const items = orgProps.wordListMenu.map(sub =>
    <SubMenu title={<span style={{paddingLeft:20}}>{sub.name}</span>} key={sub.name}>
      
        {
          sub.lists.map(name =>
                <MenuItem key={name}>
                  <span style={{paddingLeft:10}}>{name}</span>
                </MenuItem>
          )
        }
    </SubMenu>


  );

    return (
      <Menu onClick={this.handleClick} onOpenChange={this.onOpenChange} {...props} openAnimation={animation}>
        {items}

      </Menu>
    );
  }

  render() {
    const {wordListName} = this.props;
    const cat = wordListName.split(" ")[0];
    const inlineMenu = this.renderCommonMenu({
      mode: 'inline',
      defaultOpenKeys: [cat],
      selectedKeys: [wordListName],
      itemIcon,
      expandIcon,

    }, this.props);

    return (
      <div className="demo-menu">
          <div className="current-wordlist-container" onClick={this.handleMainWordClick}>
            <div className="current-wordlist">WORD LIST: {wordListName} </div>
          </div>
          { this.state.isActive && <div className="inline-menu">{inlineMenu}</div> }
      </div>
    );
  }
}

export default Demo