import React, { Component } from 'react';
import logo from './logo.svg';
import './App.scss';
import './NewHeader.css';
import SearchBar from './SearchBar';
import BookSearch from './BookSearch';
import GoogleBookSearch from './GoogleBookSearch'
import Quiz from './Quiz'
import WordBank from './WordBank'
import Flickr from './Flickr';
import './wordmap.css';

function MiddlePart(props) {
  const words = props.words;
  const WB = WordBank.getGWB();
  const items = words.map((w,idx) => {
      const l = WB.db[w] || 0;
      const c = window.WL.getWLs(w).length;
      return <div key={idx} className={`content-searcherwords vx${l}`}><a href={`#${w}`}>{w}</a><span>{c}</span></div>
    
    });
  return (
    <div id="banner">
    <div className="definition">
    <ul>
    <li>
    <span>
    <div className="byline">
    meaning
    <span>|</span>
    (noun)&nbsp;
    </div>
    isolation to prevent the spread of infectious disease
    <br/>
    </span>
    </li>
    </ul>
    </div>

    <div id="banner-feature">
    <div id="chart">
    </div>
    <nav>
    <div id="tree-icon"></div>
    <div id="star-icon"></div>
    { props.isMobile && <a id="icon-next" className="next-word arrow" alt="next word" title="Next Word"></a> }
    </nav>
    </div>

    <div id="banner-side">
    <div id="banner-side-top">

    <div className="vocab-def">
    <div className={`byline sideColor ${props.pos}`}>
    Vocabulary Definition
    </div>      
    <div className="manifesto" href="">
    <span id="vocabDefs">sensational in appearance or thrilling in effect
    <br/>
    <span className="banner-side-example">"My dog was quarantined before he could live in England"</span>
    </span>
    </div>
    </div>

    <div>
    <div className={`byline sideColor ${props.pos}`}>
    Popular Definition
    </div>      
    <div className="manifesto" href="">
    <span id="banner-side-definition">sensational in appearance or thrilling in effect
    <br/>
    <span className="banner-side-example">"My dog was quarantined before he could live in England"</span>
    </span>
    </div>
    </div>

    <div className="wordlist-div">
    <div className={`byline sideColor ${props.pos}`}>
    Word Lists:
    </div>      
    <div id="wls" className="manifesto wl" href="">

    </div>
    </div>


    <div id="wordSearcherTitle" className={`byline sideColor ${props.pos}`}>{props.title}</div>      
    <div className="manifesto" href="">
    <span id="banner-side-searcher-result">
      {items}                
    </span>

    </div>


    </div>

    <div id="banner-side-bottom">   
    <a href="" className="manifesto cboxElement display-none" rel="lightbox">
    <div>
    </div>

    </a>
    </div>
    </div>          
    </div> 
    );
}


function renderDef(props) {
  return (
    <div>
    <div className="home-cat-name">Definition</div>             
    <br/>
    <div className="page-divider"></div>
    <div id="definitions">
    <div className="readmore">adj.</div>
    <span className="bold">infuriated</span>             
    <div className="entry-summary">
    <p>enforced isolation of patients suffering from a contagious disease in order to prevent the spread of disease</p>
    </div>
    <span className="content-relatedwords"><a href="#isolation">isolation</a></span>
    <br/>          
    </div> 
    </div>

    );
}

function BottomPart(props) {
  const twitter = (
    <>
    <div className="home-cat-name">Twitter</div> 
    <span id="twitter-widget"></span>    
    </>
    );
  return(
    <div id="main">
    <div className="wrap">
    <div id="content">
    <div className="hfeed">
    { !props.isMobile && 
      <div id="post-5147" className="post">                       
      { renderDef(props) }
      </div>
    }
    <div id="post-5068" className="post">
    {props.isMobile && renderDef(props)}
    <div className="home-cat-name">USAGE IN BOOKS</div>
    <div className="page-divider"></div>
    <BookSearch
    word={props.word}
    baseUrl="https://vocabularyx.com/book"
    amzTag="alphabetlabs-20"
    />             

    </div>
    </div>
    </div>
    <div id="sidebar-primary" className="sidebar">
    <div className="home-cat-name">USAGE IN BOOKS  </div> 
    <br/>

    <div className="page-divider"></div>
    <GoogleBookSearch
    word={props.word}
    baseUrl="https://52.9.182.134:3000"
    amzTag="alphabetlabs-20"
    />

  {/*<Flickr word={props.word}/>*/}
  </div>
  </div>
  </div>
  );
}
function OldHeader() {
  return (
    <div className="App-header">
    <span className="App-header-name">dictionaryX</span>
    <span className="App-header-caption">The Fastest, Free & Fun Dictionary & Thesaurus</span>
    </div>
    );
}

function attachSignin(element) {
    // console.log(element.id);
    window.auth2.attachClickHandler(element, {},
      function(googleUser) {
        window.dx.setState({
          "gUser":googleUser
        });
      }, function(error) {
        alert(JSON.stringify(error, undefined, 2));
      });
  }
  function signOut() {
    var auth2 = window.gapi.auth2.getAuthInstance();
    auth2.signOut().then(function () {
      window.dx.setState({"gUser":null});
      attachSignin(document.getElementById("joinButton"));
      // window.gapi.signin2.render('my-signIn', {
      //         'scope': 'profile email',
      //         'width': 120,
      //         'height': 36,
      //         'longtitle': false,
      //         'theme': 'light',
      //         'onsuccess': "onSignIn",
      //         'onfailure': "onFailure"
      //       });
    });

  }


  function AccountButton(props) {
    if (props.user != null) {
      var profile = props.user.getBasicProfile();
      return (
        <div style={{display:'flex','alignItems': 'center'}}>
        <button className="clear">
        <img className="roundButton" src={profile.getImageUrl()}/>
        </button>
        <button onClick={() => signOut()}> Log out </button>
        </div>
        );
    }  else {
      return (
        <div>
        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
        <button className="round-button donate-button">
          Buy Us a Cookie <img src = "common/img/32420-cookie-icon.png" height="20"/>
        </button>
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value="PR2EXDT5TME4A" />
        <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
        </form>
        <span id="joinButton" className="App-header-caption hidden" alt="Sign up for a free membership!
        Only 4,450 free memberships remaining. Reserve yours now.">Reserve Free Year!</span>
        </div>
        );
  //      <div id="my-signIn" className="g-signin2 hidden" data-onsuccess="onSignIn"></div>
}
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wlIdx: 0,
      qType: 'mix',
      qMode: 'mix',
      isQuizMode: false,
      lastUpdate: new Date(),
      showMax: 20,
      word:'',
      lword:'',
      suggestions:[],
      questions:[
            {
              q1:
                "What is the word that means the lofty nest of a bird of prey (such as a hawk or eagle)",
              choices: ["aerie", "icon", "fowl", "quary"],
              a: 0
            },
            {
              q1: "What is the word that means the act of entering",
              choices: ["angst", "ingress", "obscure", "stipulate"],
              a: 1
            }
          ],
      count:0,
      rawWords:[],
      googleUser:null,
      wordListName: props.wordListName
    };
    this.lookup = this.lookup.bind(this);
    this.onChange = this.onChange.bind(this);
    this.updateQuizQuestionFromWordlist = this.updateQuizQuestionFromWordlist.bind(this);
    this.skipTwenty = this.skipTwenty.bind(this);
    this.refreshQMode = this.refreshQMode.bind(this);
    setInterval(() => {
      const count = this.state.word === '' ? window.WL.getWordList(this.state.wordListName).length : this.state.rawWords.length;
      const now = new Date();
      const diff = now.getTime()-this.state.lastUpdate.getTime();
      if (diff > 1000 && this.state.showMax < 100 && this.state.showMax < count) {
        this.setState({'showMax':100});
      }
      if (diff > 4000 && this.state.showMax <1000 &&this.state.showMax < count) {
        this.setState({'showMax':1000})
      }
      if (diff > 8000 && this.state.showMax <10000 &&this.state.showMax < count) {
        this.setState({'showMax':10000})
      }        
    }, 1000);
  }
  componentDidMount() {
    window.gapi.load('auth2', function(){
      // Retrieve the singleton for the GoogleAuth library and set up the client.
      window.auth2 = window.gapi.auth2.init({
        client_id: '302079354234-aet8ne1osls5u4an0j9p175nlc3deovm.apps.googleusercontent.com',
      });

      window.auth2.then(googleAuth => {
        if (googleAuth) {
          if (googleAuth.isSignedIn.get()) {
            const googleUser = googleAuth.currentUser.get();
            window.dx.setState({"gUser":googleUser});
          }
        }
      })

      attachSignin(document.getElementById('joinButton'));
    });
    this.updateQuizQuestionFromWordlist();
  }
  lookup(word) {
   if (window.wm) {
    window.wm.lookup(word); 
   }
   
   this.setState({
    word:'',
    lword:word,
    rawWords:[],
    suggestions:[],
    count:0
  });
 }

 onChange(evt) {

  var word = evt.target.value;
  this.setState({
    showMax: 20,
    lastUpdate: new Date(),
    word: word,
    count: this.state.count+1,
  });
  if (word.length === 0) {
    this.setState({
      suggestions: []
    });
    return;
  }
  if (this.props.isMobile) return;
    const url = `https://vocabularyx.com/graph/${word}`;
    // console.log(`fetching ${url}`);
    fetch(url)
    .then(resp => resp.json())
    .then(data => {
        // console.log(`data ${data}`);
        // var list = data.rawWords || [];
        var list = [];
        if (list) {
          list = list.slice(0,10);
        }
        this.setState({
          rawWords: data.rawWords,
          suggestions: this.state.count === 0? [] :list,
          count:this.state.count > 0 ? this.state.count - 1 : 0
        });  
      }); 
  }

  updateQuizQuestionFromWordlist(cb) {
    this._updateQuizQuestionFromWordlist(cb,false);
  }

  refreshQMode() {
    this._updateQuizQuestionFromWordlist(()=>this.quiz.refresh(),false);
  }

  skipTwenty(cb) {
    this._updateQuizQuestionFromWordlist(cb, true);
  }

  _updateQuizQuestionFromWordlist(cb, skipTwenty) {
    const allWords = window.WL.getWordList(window.WL.getName());
    const wb = new WordBank();
    const WB = WordBank.getGWB();
    const words = [];
    const NWORD_IN_QUIZ = 20;
    let c = 0;
    let { wlIdx } = this.state;
    if (skipTwenty) {
      for (; wlIdx < allWords.length; wlIdx++) {
        const w = allWords[wlIdx];
        const s = WB.getScore(w);
        if (s<5) {
          if (++c>=NWORD_IN_QUIZ) break;
        }
      }
    }
    const startIdx = wlIdx;
    c=0;
    for (; wlIdx < allWords.length; wlIdx++) {
      const w = allWords[wlIdx];
      const s = WB.getScore(w);
       wb.addWord(w, s);  
       words.push(w);
      if (s<5) {
        if (++c>=NWORD_IN_QUIZ) break;
      }
    }


    
    WordBank.getDefs(words).then(qMap => {
      if( skipTwenty) {
        this.setState({qMap, wbDb:wb.db,wlIdx: startIdx},cb);  
      } else {
        this.setState({qMap, wbDb:wb.db},cb);
      }
      
    })


  }
  render() {
    const {isMobile} = this.props;
    const { showMax, word, rawWords, wordListName, x, y, wbDb} = this.state;
    const { isQuizMode, qMap, qType, qMode } = this.state;
    const isGREWordList = wordListName.substr(0,3) === "GRE";
    const total = word === '' ? window.WL.getWordList(wordListName).length : rawWords.length;
    const words = word === ''
      ? window.WL.getWordList(wordListName).slice(0,showMax)
      : rawWords.slice(0,showMax);
    let percent = (100*words.length / total);
    let midTitle = word === ''
    ? `WORD LIST: ${wordListName} ` + (isGREWordList ? '' : `(${words.length})`)
    : `${this.state.rawWords.length} "${word}" matches`;
    if (percent < 100) {
      midTitle = `${Math.round(percent)}% of ${midTitle}`;
    }
    
    const content = isQuizMode ? (
      <Quiz
      ref={(ref) => {this.quiz = ref }}
      wbDb={wbDb}
      qMap={qMap}
      qType={qType}
      qMode={qMode}
      skipTwenty={this.skipTwenty}

      onAnswer={(wbDb) => {
        console.log("update wbDb " + JSON.stringify(wbDb));
        this.setState({wbDb});
      }}
    ></Quiz> 
    ):
    (
      <>
      <MiddlePart isMobile={isMobile} pos={this.state.pos} title={midTitle} words={words}/>
      <BottomPart isMobile={isMobile} word={this.state.lword} value="1"/>
      <div className="Graph"/>
      <div className="SideBar"/>
      <div className="Footer'"/>
      </>
      )

    return (
      <div className="App">
      <div className="App-header-holder">
      <div className="App-header">
      <span className="App-header-name">Vx</span>
      <AccountButton user={this.state.gUser}/>
      </div>
      <SearchBar value="1" isMobile={isMobile} isQuizMode={isQuizMode}
      lookup={this.lookup}
      setWordListName={ name => {
        window.wm.setWordList(name);
        this.setState({wordListName:name,wlIdx:0});
        this.lookup(window.WL.getNext());
        this.updateQuizQuestionFromWordlist();
      }}

      setQType={ qType => {
        this.setState({qType}, this.refreshQMode);
      }}
      setQMode={ qMode => {
        this.setState({qMode}, this.refreshQMode);
      }}
      skipTwenty={()=>this.skipTwenty(null)}

      toggleQuiz={() => {
        this.setState(prevState => ({isQuizMode: !prevState.isQuizMode}), () => {
          setTimeout(() => {
            window.wm.refresh();
          }, 500);
        });
      }}
      
      wordListMenu={this.props.wordListMenu}
      wordListName={this.state.wordListName}
      wordListNames={this.props.wordListNames}
      data={this.state.suggestions} 
      word={this.state.word} 
      onChange={this.onChange}/>
      </div>
      {content}


      </div>
      );
  }
}

export default App;
